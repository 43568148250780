import { WithLoader } from '@/providers/WithLoader';
import { WithRedux } from '@/providers/WithRedux';
import { csrfResresh } from '@/services/csrfResresh';
import '@/styles/globals.scss';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const getCSRF = async () => {
      await csrfResresh();
    };
    getCSRF();

    const mindboxInit = `
    mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
    mindbox.queue = mindbox.queue || [];
    mindbox('create');
    `;
    const mindboxInitFunc = new Function(mindboxInit);
    mindboxInitFunc();

    const yandexMetricsInit = `
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
     m[i].l=1*new Date();
     for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
     k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
     (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
  
     ym(51000866, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
     });
     `;
    const yandexMetricsInitFunc = new Function(yandexMetricsInit);
    yandexMetricsInitFunc();
  }, []);

  return (
    <>
      <Head>
        <title>Остров Мечты</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <WithRedux>
        <WithLoader>
          <Component {...pageProps} />
        </WithLoader>
      </WithRedux>
      <Script src="https://api.mindbox.ru/scripts/v1/tracker.js" async />
      <noscript>
        <div>
          <img
            src="https://mc.yandex.ru/watch/51000866"
            style={{ position: 'absolute', left: '-9999px' }}
            alt=""
          />
        </div>
      </noscript>
    </>
  );
}
